import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MainHero from "../components/mainHero"
import Challenges from "../components/challenges"
import Intro from "../components/intro"
import Solutions from "../components/solutions"
import ContactMe from "../components/contactMe"
import SEO from "../components/SEO"

export default ({
  data: {
    introContent: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <SEO />
      <MainHero
        heroImage={frontmatter.hero_image}
        title={frontmatter.hero_title}
        subTitle={frontmatter.hero_subTitle}
        height="is-medium"
      />
      <Intro
        fixed={frontmatter.headshot_image.childImageSharp.fixed}
        title={frontmatter.intro_title}
        subTitle={frontmatter.intro_subTitle}
      />
      <Challenges title={frontmatter.challenges_title}>
        {frontmatter.challenges_description}
      </Challenges>
      <Solutions
        image={frontmatter.solutions_image}
        title={frontmatter.solutions_title}
        content={frontmatter.solutions_content}
      />
      <ContactMe />
    </Layout>
  )
}
export const query = graphql`
  {
    introContent: mdx(frontmatter: { section: { eq: "intro" } }) {
      frontmatter {
        headshot_image {
          childImageSharp {
            fixed(width: 160, height: 253) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        hero_subTitle
        hero_title
        intro_subTitle
        intro_title
        challenges_title
        challenges_description
        solutions_image {
          ...SolutionImageFragment
        }
        solutions_title
        solutions_content
      }
    }
  }
`
