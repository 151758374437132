import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import RenderMarkdown from "../cms/renderMarkdown"

const Solutions = ({ image, title, content }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="columns">
          <div
            className="column is-hidden-mobile"
            style={{ position: "relative" }}
          >
            {" "}
            <Img
              fluid={image.childImageSharp.fluid}
              style={{
                position: "absolute",
                overflow: "hidden",
                width: "90%",
                height: "90%",
                top: "70px",
                left: 0,
                zIndex: "-1",
              }}
            />
          </div>
          <div className="column" style={{ maxWidth: "600px" }}>
            <h3 className="is-size-4 is-size-3-tablet  has-text-link is-capitalized has-text-centered-mobile">
              {title}
            </h3>
            <hr
              style={{
                maxWidth: "30%",
                margin: "auto",
                marginBottom: "1.6rem",
              }}
            />
            <div className="is-size-5" style={{ maxWidth: "650px" }}>
              <RenderMarkdown md={content} />
              {/* <ul>
                <li>
                  Symptom resolution associated with{" "}
                  <Link to="/" className="inline-link">
                    depression
                  </Link>{" "}
                  and{" "}
                  <Link to="/" className="inline-link">
                    anxiety
                  </Link>
                </li>
                <li>
                  Appreciation of personal strengths (“what personal strengths?”
                  you ask, which is exactly the issue)
                </li>
                <li>
                  <Link to="/" className="inline-link">
                    Reduction and management of stress
                  </Link>
                </li>
                <li>
                  Development of deeper self awareness and acceptance: getting
                  to know what makes you tick and what you want
                </li>
                <li>
                  Development of the skills of empowered and effective
                  communication, assertiveness, and empathy
                </li>
                <li>
                  Resolution of{" "}
                  <Link to="/" className="inline-link">
                    relationship issues
                  </Link>{" "}
                  such as those involving infidelity, alcoholism, and other
                  addictions
                </li>
                <li>Growth in relationship communication and intimacy</li>
                <li>
                  Personal and{" "}
                  <Link to="/" className="inline-link">
                    spiritual growth
                  </Link>
                </li>
              </ul>
              <p style={{ marginBottom: "3rem" }}>
                All of the above are discussed throughout the website. Just keep
                looking and exploring. Clicking on the highlighted words will
                take you to a new page with more information on the specified
                topic.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Solutions

export const query = graphql`
  fragment SolutionImageFragment on File {
    childImageSharp {
      fluid(maxHeight: 1100, maxWidth: 600, cropFocus: EAST) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
