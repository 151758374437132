import React from "react"
import RenderMarkdown from "../cms/renderMarkdown"

const Challenges = ({ children, title }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="has-text-centered">
          <h3 className="is-size-4 is-size-3-tablet has-text-link is-capitalized">
            {title}
          </h3>
          <hr
            style={{ maxWidth: "150px", margin: "auto", marginBottom: "3rem" }}
          />
          <div
            className="is-size-4"
            style={{ maxWidth: "750px", margin: "auto" }}
          >
            <RenderMarkdown md={children} />
            {/* <div dangerouslySetInnerHTML={{ __html: children }} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Challenges
